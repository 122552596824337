<template>
    <div>
        <div>
            <img src="@/assets/logo.png"
                 id="logo"
                 height="300"
                 style="margin-top: -3rem !important;"
                 alt="hayrat-aid-logo">
        </div>

        <p style="margin-top: 1em;">Hayrat Yardım Sosyal Gönüllülük Sistemine Hoşgeldiniz.</p>

        <p>Öncelikle Efendimiz(a.s.m) buyurmuş olduğu <strong>"Hayra vesile olan hayrı yapan gibidir."</strong>
           hadis-i şerifinin mucibince, hayırlı işlerde vesile olmanın neticesinin tıpkı
           o hayrı yapmakla eş değer olması bizlere muhteşem bir müjdedir.
            <br><br>
            Hayrat Yardım'ın duyurularını ve faaliyetlerini paylaşarak tüm islam coğrafyalarındaki mazlum ve muhtaç kardeşlerimiz için yapılmakta olan hizmetlere vesile olmaya çalışan kıymetli gönüllülerimize bir kolaylık olması adına bu sistem geliştirildi.
            <br><br>
            Sosyal Gönüllülük adı verdiğimiz bu projede, gönüllü olmak isteyen takipçilerimizin twitter hesaplarını sistemimize kayıt ediyoruz. Bu kayıt sırasında sizden aldığımız izinler çerçevesinde (beğeni, retweet izinleri) Hayrat Yardım'ın Resmi hesabından paylaşılan faaliyet ve duyuruları sistemimize kaydolan gönüllülerimizin twitter hesaplarından otomatik beğeni ve retweet yapılmasını sağlıyoruz.

            <br><br>
            Gönüllülerimiz twitter hesaplarındaki Hayrat Yardım Sosyal Gönüllülük Sistemi tarafından yapılan tüm işlemleri, detaylı bir şekilde sistem üzerinden görebilir ve daha önce verdikleri izinleri geri alabilir veya kısıtlayabilirler.

            <br><br>
            Bu vesile ile Hayrat Yardım gönüllüleri, yapılan hizmetlerin ve faaliyetlerin duyurulmasına zahmet çekmeden, unutmadan, uğraşmadan vesile olabilecek ve başta zikrettiğimiz Hadis-i Şerif'e bir nebze de olsa nail olabilmeleri sağlanacaktır.
            <br><br>
            Cenab-ı Hak rızasına mazhâr eylesin..
            <br>
            Hayrat Yardım Medya
        </p>

        <a :href="twitterGirisUrl">Twitter Hesabımı Kayıt Et</a>
    </div>
</template>

<script>
    export default {
        name: "Hosgeldiniz",
        data() {
            return {
                twitterGirisUrl: process.env.VUE_APP_BASE_API_URL + "giris/twitter"
            }
        }
    }
</script>

<style scoped>
</style>
